import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function Hero() {
  return (
    <div className="relative bg-[#e0eaf4] text-[#0d2251] flex flex-col md:flex-row items-center px-12 md:px-24 py-24 md:py-10 shadow-lg min-h-[300px]"> {/* Added min-height */}
      <div className="relative container mx-auto flex-1 md:pl-12">
        <div className="max-w-3xl">
          <h1 className="text-3xl text-[#cf772f] md:text-4xl font-bold mb-4">
            Unlock Your Business Potential
          </h1>
          <hr className="my-4 border-t border-[#0d2251] opacity-50" />
          <p className="text-lg md:text-xl mb-8 leading-relaxed"> {/* Added leading for better text spacing */}
            We offer innovative business solutions to improve your operations, streamline workflow, and help make data-driven decisions for success. With expertise in the latest technology, you’ll have the advantage to succeed and reach new heights.
          </p>
          <div className="flex flex-col sm:flex-row gap-4">
            <Link
              to="/contact"
              className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#0d2251] hover:bg-[#0a1f30] transition-colors duration-200 ease-in-out" // Added a darker hover color
            >
              Get Started
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
          </div>
        </div>
      </div>
      <div className="flex-1 flex justify-center items-center p-4"> {/* Flex container for image */}
        <img 
          src="/homepage_heroku.png" 
          alt="Business Solutions" 
          className="w-100 h-100 max-w-md md:max-w-lg rounded-md" // Added rounded corners and shadow for the image
        />
      </div>
    </div>
  );
}
