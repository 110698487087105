export default function HeroContact() {
  return (
    <div className="relative bg-[#e0eaf4] text-[#0d2251] flex flex-col md:flex-row items-center px-12 md:px-24 py-24 md:py-10 shadow-lg min-h-[300px]"> {/* Added min-height */}
      <div className="relative container mx-auto flex-1 md:pl-12">
        <div className="max-w-3xl">
          <h1 className="text-3xl md:text-4xl text-[#cf772f] font-bold mb-4">
            Let's Talk
          </h1>
          <hr className="my-4 border-t border-[#0d2251] opacity-50" />
          <p className="text-lg md:text-xl mb-8 leading-relaxed"> {/* Added leading for better text spacing */}
          We would be happy to hear from you, Please fill in the form below or mail us your requirements.
          </p>
        </div>
      </div>
      <div className="flex-1 flex justify-center items-center p-4"> {/* Flex container for image */}
        <img 
          src="/contact_us_images/Contact-us.png" 
          alt="Contact Us" 
          className="w-100 h-100 max-w-md md:max-w-lg rounded-md" // Added rounded corners and shadow for the image
        />
      </div>
    </div>
  );
}
