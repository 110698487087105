export default function Team() {
    const team = [
      {
        name: 'Gajesh Sharma',
        role: 'Web Developer',
        image: '/about_us_images/gajesh.jpg',
      },
      {
        name: 'Naman Joshi',
        role: 'Ui / Ux Designer',
        image: '/about_us_images/naman.jpg',
      },
      {
        name: 'Avinash Reddy',
        role: 'Senior Software Engineer',
        image: '/about_us_images/avinash.png',
      },
    ];
  
    return (
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Team</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Meet our team of experienced professionals dedicated to delivering
              exceptional software solutions.
            </p>
          </div>
  
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {team.map((member, index) => (
              <div key={index} className="text-center">
                <div className="relative mb-4 aspect-square overflow-hidden rounded-lg">
                  <img
                    src={member.image}
                    alt={member.name}
                    className="w-full h-full object-cover"
                  />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-1">
                  {member.name}
                </h3>
                <p className="text-gray-600">{member.role}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }