import { Code2, Menu, X } from 'lucide-react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const menuItems = [
  { path: '/', label: 'Home' },
  { path: '/about', label: 'About Us' },
  { path: '/services', label: 'Services' },
  { path: '/contact', label: 'Contact' },
];

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  return (
    <header className="fixed w-full bg-[#0d2251] shadow-md z-50">
      <div className="flex items-center justify-between px-4">
        {/* Logo shifted to the right with left margin */}
        <Link to="/" className="flex items-center space-x-2 ml-12"> {/* Added left margin to shift logo right */}
          <img src="/Ajna_Logo.png" alt="Ajna" className="h-auto w-60" />
        </Link>

        {/* Desktop Menu and Mobile Menu Button aligned to the right */}
        <div className="flex items-center pr-12"> {/* Added larger right padding to shift menu items left */}
          {/* Desktop Menu */}
          <nav className="hidden md:flex space-x-4 mr-12"> {/* Added large right margin to nav */}
            {menuItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`text-lg font-medium transition-colors hover:text-[#cf772f] hover:bg-[#152e5d] ${
                  location.pathname === item.path ? 'text-[#cf772f]' : 'text-gray-200'
                } py-2 px-4 rounded`}
              >
                {item.label}
              </Link>
            ))}
          </nav>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle menu"
          >
            {isMenuOpen ? (
              <X className="h-6 w-6 text-gray-200" />
            ) : (
              <Menu className="h-6 w-6 text-gray-200" />
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <nav className="md:hidden py-4">
          {menuItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={`block text-lg font-medium transition-colors hover:text-[#cf772f] hover:bg-[#152e5d] ${
                location.pathname === item.path ? 'text-[#cf772f]' : 'text-gray-200'
              } py-2 px-4 rounded`}
              onClick={() => setIsMenuOpen(false)}
            >
              {item.label}
            </Link>
          ))}
        </nav>
      )}
    </header>
  );
}
