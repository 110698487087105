import Clients from '../components/home/Clients.js';
import Hero from '../components/home/Hero.js';
import Services from '../components/layout/Services.js';

export default function Home() {
  return (
    <div>
      <Hero />
      <Services />
      <Clients />
    </div>
  );
}