import React from 'react';

export default function ContactUsForm() {
    return (
        <div className="bg-[#e0eaf4] text-[#0d2251] py-8 px-4 md:px-12 w-full flex flex-col md:flex-row justify-center items-center md:items-start md:items-center">
          
          {/* Image Section */}
          <div className="w-full md:w-1/3 flex justify-center mb-6 md:mb-0">
            <img 
              src="/contact_us_images/enquire_form.png" 
              alt="Contact Us Enquiry" 
              className="w-full max-w-xs md:max-w-full rounded-md shadow-md"
            />
          </div>
          
          {/* Form Section */}
          <div className="w-full md:w-2/3 max-w-2xl mx-auto border-2 flex flex-col justify-center">
            <h2 className="text-2xl font-bold mb-6 text-center md:text-left">Contact Us</h2>
            
            <form className="space-y-4">
              {/* Name Input */}
              <div className="flex items-center">
                <label htmlFor="name" className="w-1/3 text-sm font-semibold">Name</label>
                <input 
                  type="text" 
                  id="name" 
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#0d2251]"
                  placeholder="Enter your name" 
                  required 
                />
              </div>
              
              {/* Email Input */}
              <div className="flex items-center">
                <label htmlFor="email" className="w-1/3 text-sm font-semibold">Email</label>
                <input 
                  type="email" 
                  id="email" 
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#0d2251]"
                  placeholder="Enter your email" 
                  required 
                />
              </div>
              
              {/* Contact Number Input */}
              <div className="flex items-center">
                <label htmlFor="contact" className="w-1/3 text-sm font-semibold">Contact Number</label>
                <input 
                  type="tel" 
                  id="contact" 
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#0d2251]"
                  placeholder="Enter your contact number" 
                  required 
                />
              </div>
              
              {/* Message Input */}
              <div className="flex items-start">
                <label htmlFor="message" className="w-1/3 text-sm font-semibold pt-2">Message</label>
                <textarea 
                  id="message" 
                  rows="4" 
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#0d2251]"
                  placeholder="What are you looking for?" 
                  required 
                ></textarea>
              </div>
              
              {/* Submit Button */}
              <button 
                type="submit" 
                className="w-full bg-[#0d2251] text-white font-semibold py-2 rounded-md hover:bg-[#0b1d3d] transition-colors mt-4"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
    );
}
