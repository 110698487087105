import { Phone, Mail, MapPin } from 'lucide-react';

export default function CompanyDetails() {
  return (
    <div className="bg-white py-8 px-4 md:px-12">
      <div className="flex flex-col md:flex-row gap-4 justify-center items-center">
        
        {/* Phone Card */}
        <div className="bg-gray-200 p-6 rounded-lg shadow-md flex flex-col items-center w-full md:w-1/3 text-center min-h-[200px]">
          <Phone size={40} className="text-[#0d2251] mb-4" />
          <h2 className="text-lg font-semibold mb-2">Phone</h2>
          <p className="text-gray-700">+91 9920382707</p>
        </div>
        
        {/* Email Card */}
        <div className="bg-gray-200 p-6 rounded-lg shadow-md flex flex-col items-center w-full md:w-1/3 text-center min-h-[200px]">
          <Mail size={40} className="text-[#0d2251] mb-4" />
          <h2 className="text-lg font-semibold mb-2">Email</h2>
          <p className="text-gray-700">contact@ajna.cloud</p>
        </div>
        
        {/* Address Card */}
        <div className="bg-gray-200 p-6 rounded-lg shadow-md flex flex-col items-center w-full md:w-1/3 text-center min-h-[200px]">
          <MapPin size={40} className="text-[#0d2251] mb-4" />
          <h2 className="text-lg font-semibold mb-2">Address</h2>
          <p className="text-gray-700">49-9-14,I Kushal Spaces, Dwarakanagar,Visakhapatnam-530016,Andhra Pradesh</p>
        </div>
      </div>
    </div>
  );
};