import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default function Clients() {
  const clients = [
    '/client_logo/Amara_raja_group.png',
    '/client_logo/aktinos.png',
    '/client_logo/apicore.png',
    '/client_logo/azico.png',
    '/client_logo/Clinovi.png',
    '/client_logo/everest_logo.png',
    '/client_logo/hindustan_phosphates.png', 
    '/client_logo/integrin.png', 
    '/client_logo/krs.png',
    '/client_logo/mediviz.png', 
    '/client_logo/rakshitdrugs.png', 
    '/client_logo/ravoos.png', 
    '/client_logo/siflon.png', 
    '/client_logo/spansules.png', 
    '/client_logo/stayonpapers.png',
    '/client_logo/synac.png',
    '/client_logo/synergene.png', 
    '/client_logo/vijayasri.png'
  ];

  return (
    <section className="py-20 bg-[#e0eaf4] text-[#0d2251]">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold text-[#0d2251] mb-4">
          Trusted by Industry Leaders
        </h2>
        <p className="text-[#0d2251] max-w-2xl mx-auto">
          We're proud to work with some of the world's most innovative companies,
          helping them achieve their digital transformation goals and meet compiance needs.
        </p>
      </div>

      <div className="w-full px-6 md:px-12"> {/* Add padding on left and right */}
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={30}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          loop={true} // Enable circular sliding
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
          style={{ height: '240px' }} // Fixed height
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          }}
        >
          {clients.map((client, index) => (
            <SwiperSlide key={index} className="flex items-center justify-center p-8 rounded-lg h-full">
              <img
                src={client}
                alt="Client"
                className="h-25 w-full object-cover rounded-md mb-4" // Adjust height and width as needed
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
