import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

const services = [
  {
    image: '/qr_code.png', // Add the correct image path
    title: 'Pharma API QRCode',
    description:
      'The ideal answer to all of your regulatory compliance and traceability needs. You can manage your labeling process in a streamlined, effective, and economical way with our cutting-edge technology.',
  },
  {
    image: '/cloud-erp.png', // Add the correct image path
    title: 'Cloud ERP',
    description:
      'The ideal solution for your regulatory compliance and traceability needs. With Cloud ERP, seamlessly manage your labeling process in a streamlined, efficient, and highly cost-effective manner.',
  },
  {
    image: '/printlab.png', // Add the correct image path
    title: 'PrintLab',
    description:
      'A strong and user-friendly tool for designing and printing labels of a professional calibre. With a variety of features and an intuitive user interface, PrintLab offers a complete solution for all your labelling requirements.',
  },
  {
    image: '/seo.png', // Add the correct image path
    title: 'SEO Optimization',
    description:
      'The ultimate solution for optimizing your website\'s search engine visibility. With AjnaCloud SEO Optimization, enhance your website\'s ranking and visibility in a streamlined, efficient, and highly effective manner.',
  },
  {
    image: '/app_development.png', // Add the correct image path
    title: 'Custom App Development',
    description:
      'Custom app development tailored to your business needs. Our expert team designs scalable, user-friendly applications across platforms, employing agile methodologies for flexibility and transparency.',
  },
  {
    image: '/web-development.png', // Add the correct image path
    title: 'Web Development',
    description:
      'Ajna.cloud\'s expertise extends to web development tasks that encompass content management systems, e-commerce platforms, online marketing campaigns, and a range of other responsibilities.',
  },
  {
    image: '/digital_marketing.png', // Add the correct image path
    title: 'Digital Marketing',
    description:
      'The ultimate solution for enhancing your online presence. With AjnaCloud Digital Marketing, maximize your reach, engagement, and conversions in a streamlined, efficient, and highly effective manner.',
  },
  {
    image: '/dms.png', // Add the correct image path
    title: 'AJNA (DMS)',
    description:
      'The ideal solution for your regulatory compliance and traceability needs. With AJNA (DMS), seamlessly manage your labeling process in a streamlined, efficient, and highly cost-effective manner.',
  },
];

export default function Services() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="w-full px-6 md:px-12">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-[#0d2251] mb-4">Our Services</h2>
          <p className="text-[#0d2251] max-w-2xl mx-auto">
            We offer a comprehensive range of software development and IT consulting services to help businesses thrive in the digital age.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
            >
              <img
                src={service.image}
                alt={service.title}
                className="h-48 w-full object-contain rounded-md mb-4" // Updated to "object-contain"
              />
              <div className="bg-[#e0eaf4] p-4 rounded-md mb-4">
                <h3 className="text-xl font-semibold text-[#0d2251] mb-2">
                  {service.title}
                </h3>
                <p className="text-[#0d2251] mb-4">
                  {service.description}
                </p>
              </div>
              <Link
                to="/services"
                className="text-blue-600 hover:text-blue-700 font-medium inline-flex items-center"
              >
                Learn more
                <ArrowRight className="ml-2 h-4 w-4" />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
