import { ArrowRight } from 'lucide-react';

export default function WhyUs() {
  return (
    <div className="relative bg-white text-[#0d2251] flex flex-col md:flex-row items-center px-12 md:px-24 shadow-lg min-h-[600px]"> {/* Added min-height */}
      <div className="flex-1 flex justify-center items-center p-4"> {/* Flex container for image */}
        <img 
          src="/about_us_images/aboutmiddke.webp" 
          alt="About Us" 
          className="w-100 h-100 max-w-md md:max-w-lg rounded-md" // Added rounded corners and shadow for the image
        />
      </div>
      <div className="relative container mx-auto flex-1 md:pl-12">
        <div className="max-w-3xl">
          <h1 className="text-3xl md:text-4xl font-bold mb-4">
            Why Us?
          </h1>
          <hr className="my-4 border-t border-[#0d2251] opacity-50" />
          <p className="text-lg md:text-xl mb-8 leading-relaxed"> {/* Added leading for better text spacing */}
          At AJNA, our team of professionals is committed to assisting you in maximizing the potential of our platform. You can depend on us to be your technology partner and assist in taking your company to the next level thanks to our dedication to giving you the support you need to succeed.
          </p>
        </div>
      </div>
    </div>
  );
}
